import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle } from "@material-ui/icons";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import ApiCall from "../../api/ApiCall";
import { AreaRoutes } from "../../api/Routes";
import { startApiCall } from "../../GlobalFunctions/GlobalFunctions";
import { resolveErrorMessage } from "../../services/service";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

const ChooseZoneDropDown = ({ selectedZone, setselectedZone }) => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const [allZones, setallZones] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [page, setpage] = useState(1);
  const [searchKey, setsearchKey] = useState("");

  const handleGetZones = async () => {
    startApiCall(null, setloading);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
        searchKey: searchKey,
      },
      page: page,
      limit: 100,
    };
    const res = await ApiCall("post", AreaRoutes.getZone, data);
    if (res?.success) {
      if (page === 1) {
        setallZones(res?.zones);
        settotalCount(res?.zones?.totalCount);
      } else {
        setallZones((prev) => [...prev, ...res?.zones]);
        settotalCount(res?.data?.properties?.totalCount);
      }
      setloading(false);
    } else {
      setloading(false);
      resolveErrorMessage(res?.error);
    }
  };

  useEffect(() => {
    handleGetZones();
  }, [page, searchKey]);

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== allZones?.length
    ) {
      setpage((prev) => prev + 1);
    }
  };

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={false}
        onChange={(e, value) => {
          setselectedZone(value);
        }}
        id="zone-search"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        freeSolo
        value={selectedZone}
        classes={classes}
        options={allZones}
        autoHighlight
        getOptionLabel={(option) => option}
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option}>
                {selectedZone?.length > 0 && selectedZone.includes(option) && (
                  <CheckCircle
                    style={{
                      color: "rgb(36 202 38)",
                      fontSize: "14px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {option}
              </li>
            </div>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              required
              {...params}
              onChange={(e) => {
                setpage(1);
                setsearchKey(e.target.value);
              }}
              onBlur={() => {
                setsearchKey("");
              }}
              label={
                loading ? "Loading..." : `Select Zone (${allZones?.length})`
              }
              inputProps={{
                ...params.inputProps,
              }}
            />
          );
        }}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
};

export default ChooseZoneDropDown;
