import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import { Grid, Avatar, Typography, Paper } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { Cancel, CheckCircle } from "@material-ui/icons";
import Tooltip from "@mui/material/Tooltip";
import ChangeAddressModal from "./ChangeAddressModal";
import moment from "moment";
import { BASE_URL } from "../../../../utils/base";
import { resolveErrorMessage } from "../../../../services/service";
import * as api from "../../../../api";
import { toast } from "react-toastify";
import UserProfileDetailsModal from "../../../../GlobalComponents/UserProfileDetailsModal/UserProfileDetailsModal";
import { handleGetCheckModulePermission } from "../../../../contextAPI/checkModulesPermission";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import ResidentConfirmModal from "./ResidentConfirmModal";

function PendinglRequeststable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    allPendingRequests,
    page,
    getAllPendingRequests,
    user,
    usermoduleswithpermissions,
    selectableHeader,
  } = props;
  const [changeAddressModalOpen, setchangeAddressModalOpen] = useState(false);
  const [changeAddressUser, setchangeAddressUser] = useState([]);
  const [IsChangeStatus, setIsChangeStatus] = useState(false);
  const [selectedStatus, setselectedStatus] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [isShowUserProfileDetails, setisShowUserProfileDetails] =
    useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [currentResidentFlatId, setcurrentResidentFlatId] = useState(null);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Resident"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const rejectConfirmation = () => {
    setIsChangeStatus(false);
  };
  const handleChangeResidentStatus = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        userId: changeAddressUser?._id,
        isApproved: selectedStatus === "approve" ? true : false,
        adminId: user?._id,
        flatId: changeAddressUser?.pendingRequests?.[0]?.flatId?._id,
      };
      const res = await api.changeResidentRequests(sendData);
      if (res?.data?.success) {
        setloader(false);
        setIsChangeStatus(false);
        getAllPendingRequests();
        toast.success("Resident Status Changed Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const handleSetForStatusChange = (status, resident) => {
    setcurrentResidentFlatId(resident?.pendingRequests?.[0]?.flatId?._id);
    setchangeAddressUser(resident);
    setselectedStatus(status);
    seterrorMsg("");
    setIsChangeStatus(true);
  };
  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Time
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Email
              </TableCell>
              <TableCell
                className={classes.tableHeaderCell}
                align="center"
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Phone Number
              </TableCell>
              {selectableHeader !== "Manager" && (
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Address
                </TableCell>
              )}

              {isReadWrite && (
                <TableCell
                  className={classes.tableHeaderCell}
                  align="center"
                  sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allPendingRequests
              ?.filter?.(
                (data) =>
                  data?.pendingRequests?.[0]?.flatId?.society?._id ===
                  fetchSocietyId()
              )
              ?.map((resident, index) => (
                <>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {(page - 1) * 50 + (index + 1)}.{" "}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setchangeAddressUser(resident);
                        setisShowUserProfileDetails(true);
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <Avatar
                          style={{ cursor: "pointer" }}
                          alt={resident.name}
                          src={
                            resident.image
                              ? `${BASE_URL}/user/${resident.image}`
                              : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          }
                          className={classes.avatar}
                        />
                      </Grid>
                      <Typography
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          textTransform: "capitalize",
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {resident?.name}
                      </Typography>
                      <span
                        style={{
                          width: "57px",
                          padding: 5,
                          textTransform: "capitalize",
                          marginLeft: 5,
                        }}
                        class={"badge bg-primary"}
                      >
                        {resident?.pendingRequests?.[0]?.userRole}
                      </span>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {moment(resident?.updatedAt).format(
                        "DD/MM/YYYY - h:mm A"
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {resident?.email || resident?.email !== ""
                        ? resident?.email
                        : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "black",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {resident?.phone}
                    </TableCell>
                    {selectableHeader !== "Manager" && (
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                          textTransform: "capitalize",
                          fontSize: "13px",
                        }}
                      >
                        {resident?.pendingRequests?.[0]?.flatId?.property?.name}{" "}
                        - {resident?.pendingRequests?.[0]?.flatId?.floor?.name}{" "}
                        - {resident?.pendingRequests?.[0]?.flatId?.number}
                        {isReadWrite && (
                          <Typography
                            style={{
                              color: "#2f45c5",
                              fontFamily: "Poppins, sans-serif",
                              textTransform: "capitalize",
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontWeight: "bold",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              setchangeAddressUser(resident);
                              setchangeAddressModalOpen(true);
                            }}
                          >
                            Change
                          </Typography>
                        )}
                      </TableCell>
                    )}

                    {isReadWrite && (
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <Grid
                          container
                          style={{
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip
                            title="Approve Request"
                            placement="top"
                            arrow
                          >
                            <CheckCircle
                              onClick={() => {
                                if (
                                  selectableHeader !== "Manager" &&
                                  (resident?.pendingRequests?.[0]?.flatId
                                    ?.property === null ||
                                    resident?.pendingRequests?.[0]?.flatId
                                      ?.floor === null ||
                                    resident?.pendingRequests?.[0]?.flatId ===
                                      null)
                                ) {
                                  toast.error(
                                    "Please add address for resident first"
                                  );
                                } else {
                                  handleSetForStatusChange("approve", resident);
                                }
                              }}
                              style={{
                                color:
                                  resident?.pendingRequests?.[0]?.flatId
                                    ?.property === null ||
                                  resident?.pendingRequests?.[0]?.flatId
                                    ?.floor === null ||
                                  resident?.pendingRequests?.[0]?.flatId ===
                                    null
                                    ? "#6c757d"
                                    : "green",
                                fontSize: "20px",
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            onClick={() => {
                              handleSetForStatusChange("reject", resident);
                            }}
                            title="Reject Request"
                            placement="top"
                            arrow
                          >
                            <Cancel
                              style={{
                                color: "red",
                                fontSize: "20px",
                                marginLeft: 4,
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ChangeAddressModal
        changeAddressModalOpen={changeAddressModalOpen}
        setchangeAddressModalOpen={setchangeAddressModalOpen}
        changeAddressUser={changeAddressUser}
        getAllPendingRequests={getAllPendingRequests}
        user={user}
        type="Pending"
        userAddressDetails={changeAddressUser?.pendingRequests?.[0]?.flatId}
      />
      <ResidentConfirmModal
        title={`ARE YOU SURE YOU WANT TO ${selectedStatus} THIS RESIDENT ?`}
        description="A confirmation message will appear when this process is
      complete.You can confirm by clicking below."
        loader={loader}
        handleConfirmation={handleChangeResidentStatus}
        rejectConfirmation={rejectConfirmation}
        isOpen={IsChangeStatus}
        errorMsg={errorMsg}
        flatId={currentResidentFlatId}
        selectedStatus={selectedStatus}
      />
      <UserProfileDetailsModal
        isOpen={isShowUserProfileDetails}
        setIsOpen={setisShowUserProfileDetails}
        user={changeAddressUser}
      />
    </>
  );
}

export default PendinglRequeststable;
